export const ADD_TO_CART = 'ADD_TO_CART';
export const INCREASE_ITEM_QTY = 'INCREASE_ITEM_QTY';
export const DECREASE_ITEM_QTY = 'DECREASE_ITEM_QTY';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CART_RESET = 'CART_RESET';

export const SAVE_CART_REQUEST = 'SAVE_CART_REQUEST';
export const SAVE_CART_SUCCESS = 'SAVE_CART_SUCCESS';
export const SAVE_CART_FAIL = 'SAVE_CART_FAIL';

export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAIL = 'UPDATE_CART_FAIL';