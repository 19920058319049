export const GET_LISTS_REQUEST = "GET_LISTS_REQUEST";
export const GET_LISTS_SUCCESS = "GET_LISTS_SUCCESS";
export const GET_LISTS_FAIL = "GET_LISTS_FAIL";

export const NEW_LIST_REQUEST = "NEW_LIST_REQUEST";
export const NEW_LIST_SUCCESS = "NEW_LIST_SUCCESS";
export const NEW_LIST_FAIL = "NEW_LIST_FAIL";

export const UPDATE_LIST_REQUEST = "UPDATE_LIST_REQUEST";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";
export const UPDATE_LIST_FAIL = "UPDATE_LIST_FAIL";

export const CANCEL_LIST_REQUEST = "CANCEL_LIST_REQUEST";
export const CANCEL_LIST_SUCCESS = "CANCEL_LIST_SUCCESS";
export const CANCEL_LIST_FAIL = "CANCEL_LIST_FAIL";

export const COMPLETE_LIST_REQUEST = "COMPLETE_LIST_REQUEST";
export const COMPLETE_LIST_SUCCESS = "COMPLETE_LIST_SUCCESS";
export const COMPLETE_LIST_FAIL = "COMPLETE_LIST_FAIL";

export const GET_LIST_DETAILS_REQUEST = "GET_LIST_DETAILS_REQUEST";
export const GET_LIST_DETAILS_SUCCESS = "GET_LIST_DETAILS_SUCCESS";
export const GET_LIST_DETAILS_FAIL = "GET_LIST_DETAILS_FAIL";
export const GET_LIST_DETAILS_RESET = "GET_LIST_DETAILS_RESET";

