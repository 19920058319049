export const GET_ITEMS_REQUEST = "GET_ITEMS_REQUEST";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";

export const NEW_ITEM_REQUEST = "NEW_ITEM_REQUEST";
export const NEW_ITEM_SUCCESS = "NEW_ITEM_SUCCESS";
export const NEW_ITEM_FAIL = "NEW_ITEM_FAIL";

export const GET_ITEM_DETAILS_REQUEST = "GET_ITEM_DETAILS_REQUEST";
export const GET_ITEM_DETAILS_SUCCESS = "GET_ITEM_DETAILS_SUCCESS";
export const GET_ITEM_DETAILS_FAIL = "GET_ITEM_DETAILS_FAIL";
export const GET_ITEM_DETAILS_RESET = "GET_ITEM_DETAILS_RESET";

